<template>
  <div>
    <header-slot>
      <template #actions>
        <b-button
          variant="outline-success"
          class="mr-1"
          @click="openModalRegister()"
        >
          <feather-icon
                icon="PlusIcon"
                size="15"
                class="text-success"
                style="margin-right: 0.5rem;"
          />REGISTER PRODUCT
        </b-button>
      </template>
    </header-slot>
    <!-- Dynamic Route -->
    <b-nav
      card-header
      pills
      class="m-0"
    >
      <b-nav-item
        :to="{ name: 'lo-list-merchandising' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >Products
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="key" />
    </b-card>
    <register-merchandising
      v-if="modalRegister"
      @close="closeModalRegister"
      @justClose="justCloseModalRegister"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
// import grilla
import GrillaMerchandising from '@/views/logistic/views/merchandising/components/MerchandisingGrillas.vue';
// modals
import RegisterMerchandising from './modals/RegisterMerchandising.vue';

export default {
  components: {
    'grilla-merchandising': GrillaMerchandising,
    'register-merchandising': RegisterMerchandising,
  },
  data() {
    return {
      modalRegister: false,
      key: 0,
    };
  },
  methods: {
    openModalRegister() {
      this.modalRegister = true;
    },
    closeModalRegister() {
      this.modalRegister = false;
      this.key++;
    },
    justCloseModalRegister() {
      this.modalRegister = false;
    },
  },
  computed: {
    ...mapGetters('auth', ['currentUser']),
  },
  watch: {
    $router() {
      this.key++;
    },
  },
};
</script>
